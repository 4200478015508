import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Form, Divider, message } from 'antd';
import { MdOutlineMail, MdPassword } from 'react-icons/md';
import { Button, Input, Text } from '@geist-ui/core';
import HomeLayout from '../blocks/layout/home';
import Seo from '../blocks/layout/seo';

import { Firebase } from '../config/firebase';
import '../styles/auth-page.scss';

const LoginPage = () => {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false);
      const [googleLoading, setGoogleLoading] = useState(false);
      const [, forceUpdate] = useState({});

      useEffect(() => {
            forceUpdate({});
            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        navigate('/dashboard');
                  }
            });
      }, []);

      const loginWithEmail = (values) => {
            const { email, password } = values;
            setLoading(true);

            Firebase.auth()
                  .signInWithEmailAndPassword(email, password)
                  .then((result) => {
                        window.localStorage.removeItem('active_project_id');
                        setLoading(false);
                        message.success('Logged-in successful!');
                  })
                  .catch((err) => {
                        setLoading(false);
                        if (err.code === 'auth/user-not-found') {
                              message.info(
                                    'There is no user record corresponding to this identifier. The user may have been deleted.'
                              );
                        } else {
                              message.error(err.message);
                        }
                  });
      };

      const loginWithGoogle = (e) => {
            e.preventDefault();
            setGoogleLoading(true);
            const providerOAuth = new Firebase.auth.GoogleAuthProvider();

            Firebase.auth()
                  .signInWithPopup(providerOAuth)
                  .then((result) => {
                        window.localStorage.removeItem('active_project_id');
                        setGoogleLoading(false);
                  })
                  .catch((err) => {
                        setGoogleLoading(false);
                        if (
                              err.code ===
                              'auth/account-exists-with-different-credential'
                        ) {
                              message.info('Try to use email-password login');
                        } else {
                              message.error(err.message);
                        }
                  });
      };

      return (
            <div className="auth-page">
                  <Seo title="Login — Output" />
                  <HomeLayout authPage>
                        <div className="auth-form">
                              <div className="heading">
                                    <Text h3>Login to dashboard</Text>
                                    <Button
                                          width="350px"
                                          style={{
                                                padding: '0px 30px',
                                                width: 300,
                                                fontSize: 15,
                                                marginTop: 40,
                                          }}
                                          loading={googleLoading}
                                          onClick={loginWithGoogle}
                                    >
                                          <img
                                                src="/google-logo.png"
                                                alt="google login"
                                                height="28px"
                                                width="28px"
                                                style={{
                                                      marginRight: 15,
                                                }}
                                          />
                                          <span>Login with Google</span>
                                    </Button>
                                    <Divider
                                          plain
                                          style={{
                                                margin: '40px 0px',
                                                fontWeight: 300,
                                                fontSize: 13,
                                                color: '#606060',
                                          }}
                                    >
                                          or login with email
                                    </Divider>
                                    <Form
                                          form={form}
                                          name="login_form"
                                          className="auth-input-form"
                                          initialValues={{ remember: true }}
                                          onFinish={loginWithEmail}
                                    >
                                          <Form.Item name="email">
                                                <Input
                                                      placeholder="Email Address"
                                                      required
                                                      width="350px"
                                                      icon={<MdOutlineMail />}
                                                />
                                          </Form.Item>
                                          <Form.Item name="password">
                                                <Input.Password
                                                      placeholder="Password"
                                                      required
                                                      width="350px"
                                                      icon={<MdPassword />}
                                                />
                                          </Form.Item>
                                          <Form.Item shouldUpdate>
                                                {() => (
                                                      <Button
                                                            type="success"
                                                            htmlType="submit"
                                                            width="350px"
                                                            loading={loading}
                                                            className="auth-btn auth-form-button"
                                                      >
                                                            Login now
                                                      </Button>
                                                )}
                                          </Form.Item>
                                    </Form>

                                    {/* <ForgotPwd /> */}
                              </div>
                        </div>
                  </HomeLayout>
            </div>
      );
};

export default LoginPage;
